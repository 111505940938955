import { Injectable, inject } from '@angular/core';
import type { IBuilding } from '@app/shared/interfaces/building.interfaces';
import { MemoizedSelector, Store } from '@ngrx/store';
import { combineLatest, filter, map } from 'rxjs';
import { ICmmsFilter } from '../cmms/components/cmms-sort-filters-grouping/interfaces-and-types/filter.interfaces';
import { buildingsList } from '@app/layout/state/layout.selectors';

@Injectable({
	providedIn: 'root',
})
export class MyBuildingLocationsService {
	private readonly store$ = inject(Store);

	public getCurrentLocationIds(
		currentBuildingIdSelector: MemoizedSelector<unknown, string>
	) {
		return combineLatest([
			this.store$.select(currentBuildingIdSelector),
			this.store$.select(buildingsList),
		]).pipe(
			filter(([_, buildings]) => buildings != null),
			map(([currentBuildingId, buildings]) =>
				getAllLocationIdsForBuilding(currentBuildingId, buildings)
			)
		);
	}

	public getLocationFilters(
		currentBuildingIdSelector: MemoizedSelector<unknown, string>,
		filters: ICmmsFilter[]
	) {
		return this.getCurrentLocationIds(currentBuildingIdSelector).pipe(
			map(locationIds => preSelectLocationFilter(filters, locationIds))
		);
	}
}

function getAllLocationIdsForBuilding(
	buildingId: string | undefined,
	buildings: IBuilding[]
): string[] {
	if (buildingId == null) {
		return [];
	}

	const building = (buildings ?? []).find(b => b.id === buildingId);
	if (building != null) {
		const subIds = building.floor.flatMap(floor => [
			floor.id,
			...floor.room.map(room => room.id),
		]);
		return [buildingId, ...subIds];
	}
	return [];
}

function preSelectLocationFilter(
	filters: ICmmsFilter[],
	locationIds: string[]
): ICmmsFilter[] {
	if (locationIds.length === 0) {
		return filters;
	}

	return filters.map<ICmmsFilter>(cmmsFilter => {
		if (cmmsFilter.type === 'location') {
			return {
				...cmmsFilter,
				preselectedValue: locationIds,
			};
		}
		return cmmsFilter;
	});
}
